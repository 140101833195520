@import "styles/variables";

.root {
  min-height: 100vh;
  margin: auto;

  :global([class^="mobile-top-panel_root"]) {
    display: none;
  }

  @media (min-width: 0) and (max-width: $tablet-width) {
    :global([class^="header_root"]) {
      display: none;
    }

    :global([class^="mobile-top-panel_root"]) {
      display: block;
    }
  }
}

/*.root,
.content {
  max-width: $max-page-width;
}*/

.content {
  @include border-radius($def-br $def-br 0 0);
  flex-grow: 1;
  background-color: $white;
  display: flex;
  flex-direction: column;
  z-index: 2;
  overflow: hidden;

  @media (min-width: 0) and (max-width: $tablet-width) {
    @include border-radius(0);
    background-color: transparent;
    border: none;
    margin: {
      top: 0;
      bottom: 0;
    }
  }

  &.mobile_no_margin {
    @media (max-width: $mobile-width) {
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
}

.view {
  flex-grow: 1;
  display: flex;

  > div {
    width: 100%;
  }
}