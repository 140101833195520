@import "styles/variables";

.root {
  padding: 0 20px;
  border-bottom: 2px solid $landing-back-grey;
  margin: 26px 0;

  .title_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
    cursor: pointer;
    align-items: center;

    :global(.MuiSvgIcon-root) {
      font-size: 26px;
    }
  }

  .title {
    color: $landing-grey;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.25px;
    text-transform: uppercase;
  }

  svg {
    color: $landing-grey;
  }

  .content {
    display: none;
    opacity: 0;
    transition: all 0.3s ease;

    &:global(.show) {
      display: block;
      opacity: 1;
      padding-bottom: 27px;
    }
  }


}