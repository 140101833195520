@import "styles/variables";

$social-icon-size: rem-calc(36);

.root {
  position: fixed;
	top: $site-top-panel-height;
	left: -#{$side-menu-width};
	bottom: 0;
	width: $side-menu-width;
  display: flex;
  flex-direction: column;
  background-color: $white;
  overflow: hidden;
  border-right: rem-calc(4) solid $app-bg;
	transition: left ease-in-out 0.3s;
	z-index: 9998;
}

.overlay {
  position: fixed;
	top: $site-top-panel-height;
  left: 0;
	bottom: 0;
	background-color: transparent;
	transition: background-color ease-in-out 0.3s;
	z-index: 9997;
}

.header {
  display: flex;
  align-items: center;
  line-height: 1;
  padding: rem-calc(15) * 2 $padding-md * 3;

  .project {
    text-transform: lowercase;
    color: $brand;
    font: {
      weight: 600;
      size: rem-calc(38);
    }
  }

  .project_slogan {
    padding-left: $padding-sm;
    margin-top: rem-calc(5);
    color: $brand-dark;
    font: {
      weight: 600;
      size: $font-xs2;
    }
  }
}

.nav {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: $padding-md * 2 $padding-md * 3;

  .link {
    display: block;
    margin-bottom: $margin-md * 2;
    color: lighten($brand-dark, 20%);
    transition: font-family ease-in-out 0.3s;
    font: {
      weight: 300;
      size: $font-md;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .link_border {
        background-color: $app-bg;
      }
    }
  }

  .link_border {
    border-radius: rem-calc(3);
    width: rem-calc(15);
    height: rem-calc(3);
    margin-top: rem-calc(3);
    transition: background-color ease-in-out 0.3s;
  }

  .link_active {
    color: $brand-darkest;
    font: {
      weight: 600;
    }

    .link_border {
      background-color: $brand;
    }
  }
}

.nav_content {
  position: relative;
}

.social {
  display: flex;
  align-items: center;
  padding: 0 $padding-md * 2;

  a {
    border-radius: 50%;
    background-color: $app-bg;
    width: $social-icon-size;
    height: $social-icon-size;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-right: $margin-md;

    &:last-child {
      margin-right: 0;
    }
  }
}

.support {
  padding: $padding-md * 2 $padding-md * 2 0;
  font: {
    weight: 300;
    size: $font-md2;
  }

  a {
    display: flex;
    align-items: center;
    color: lighten($brand-dark, 25%);


    svg {
      margin-right: $margin-sm;
    }
  }
}

.footer {
  position: relative;
  color: lighten($brand-dark, 35%);
  line-height: 1;
  padding: {
    top: $padding-md * 2;
    right: $padding-md * 2;
    bottom: $padding-md * 3;
    left: $padding-md * 2;
  }
  font: {
    weight: 300;
    size: $font-xs2;
  }
}