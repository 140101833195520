@import "styles/variables";

$border: rem-calc(1) dotted transparent;

.root {
  display: flex;
  align-items: center;
  position: relative;  
  z-index: 2;
  max-width: rem-calc(350);
  background-color: $landing-back-grey;
  border-radius: 4px;
  height: 32px;
  padding: 0 10px;
  color: $landing-grey;
  font-family: $montserrat;

  svg {
    margin-right: 8px;
  }

  :global(.item) {
    display: flex!important;
    align-items: center;
    color: $landing-grey!important;
    font-size: 15px!important;

    svg {
      margin-right: 10px;
      font-size: 22px;
    }

    &:hover {
      background-color: #fff!important;
      color: $primary!important;
    }
  }

  :global(.menu) {
    box-shadow: 0 5px 10px rgba(0,0,0,0.1)!important;
    border-color: $landing-back-grey!important;
  }
}