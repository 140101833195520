@import "styles/variables";

$border-size: rem-calc(4);
$action-icon-size: rem-calc(40);

.root {
  background-color: #fff;
  margin-top: 64px;
  width: 100%;
  color: $landing-dark-grey;

  [class^="language-switcher_root"] {
    margin-right: 20px;
  }

  :global(.z-button) {
    min-width: 170px;
  }
}

.container {
  width: 100%;
  max-width: $max-page-width;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.logo {
  height: rem-calc(40);
  display: flex;
  align-items: center;
  margin-right: 20px;

  img {
    height: rem-calc(40);
  }
}

.user_actions {
  padding-right: $padding-md;

  :global([class^="ui-circle-button_component"]:not(:last-child)) {
    margin-right: $margin-sm;
  }
}

.action {
  display: flex;
  align-items: center;
  line-height: 1;
  color: $default-text-color;
  transition: color ease-in-out 0.3s;

  &:hover {
    color: $danger;
  }

  &:not(:last-child) {
    margin-right: $margin-md * 2;
  }

  .action_icon {
    @include border-radius(50%);
    background-color: $app-bg;
    width: $action-icon-size;
    height: $action-icon-size;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $margin-md;
  }
}

.access {
  display: flex;
  padding: {
    left: $padding-md * 2;
    right: $padding-md;
  }
}

.sections {
  margin-left: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}

.about {
  padding: 7.5px 12px;
  margin-right: 10px;
  background: #F6F8FA;
  border-radius: 4px;
  color: #274568;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: .25px;
  text-transform: uppercase;
}

.auth{
  background: #F6F8FA;
  cursor: pointer;
  padding: 0 6px;
  height: 33px;
  display: flex;
  align-items: center;
  margin-left: -10px;
  border-radius: 6px;
  color: #274568;

  svg{
    color: #274568;
  }
}

.section {
  display: flex;
  align-items: center;
  color: $landing-grey;
  text-transform: uppercase;
  padding: 10px 0;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 1.4;
  cursor: pointer;
  transition: border linear 0.3s;
  font-family: 'Montserrat', sans-serif;
  margin-right: 40px;


  &:hover {
    color: $primary;
  }

  :global(.MuiSvgIcon-root) {
    position: relative;
    top: -1px;
    font-size: 20px;
    display: inline-block;
    margin-left: 5px;
  }
}

.modal_container {
  width: 100%;
  max-width: $max-page-width;
  margin: auto;
}

.nav_modal {
  position: absolute;
  top: -1000px;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  padding: 100px 0;
  transition: top 0.3s ease;
  font-family: $montserrat;
  &:global(.visible) {
    top: 0;
  }

  .navigation {
    display: flex;

    nav {
      display: flex;
      width: 100%;
    }

    a {
      position: relative;
      font-family: 'Noto Sans', 'Montserrat', sans-serif;
      transition: 0.1s color linear;

      .feature_title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 700;
        color: $landing-grey;
        text-transform: uppercase;
      }

      .feature_description {
        font-family: $montserrat;
        font-size: 14px;
        font-weight: 400;
        color: $landing-dark-grey;
        letter-spacing: 0.5px;
      }

      :global(span) {
        margin-right: 12px;
      }

      :global(svg) {
        font-size: 20px;
        line-height: 20px;
        opacity: 0;
        transition: 0.25s opacity linear;
        color: $primary;
      }

      &:hover {
        color: $primary;

        .feature_title {
          color: $primary;
        }
        :global(svg) {
          opacity: 1;
        }

        .feature_description {
          color: $primary;
        }
      }
    }

    .nav_block {
      width: 25%;

      >a {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 700;
        color: $landing-grey;
        text-transform: uppercase;

        &:hover {
          color: $primary;
        }
      }
    }

    .feature_block {
      width: 25%;
      padding-right: 40px;
      margin-bottom: 20px;
    }
  }

  .nav_header_row {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .nav_title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    color: $landing-dark-grey;
    margin-right: auto;
  }

  .close_button {
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    svg {
      font-size: 40px;
    }
  }
}

.modal_overlay {
  background-color: transparent;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s ease;

  &:global(.visible) {
    bottom: 0;
    background-color: $landing-overlay;
  }
}

.tariffs_row {
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  justify-content: space-between;
}

.tariff {
  padding: 40px;
  background-color: $landing-back-grey;
  margin: 0 20px 40px;
  flex-basis: 33%;

  &.dark_bg {
    background-color: $font-color;
    color: #fff;

    a {
      background-color: $danger!important;
      color: $white;
      opacity: 0.85;
      transition: opacity ease 300ms;

      &:hover {
        opacity: 1;
      }
    }
  }


  .name {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.18px;
    margin-bottom: 20px;
  }

  .price_row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
  }

  .price {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 800;
    margin-right: 10px;
  }

  .price_old {
    color: $danger;
    font-size: 24px;
    font-weight: 700;
    margin-right: 10px;
    text-decoration: line-through;
    text-decoration-color: $landing-grey;
    padding-bottom: 8px;
  }

  .price_currency {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 6px;
  }

  .payment_period {
    font-size: 16px;
    letter-spacing: 0.5px;
    margin-bottom: 40px;
  }

  .action {
    margin-top: 4px;
  }
}

.tariffs_bottom {
  display: flex;
  flex-wrap: wrap;

  .tariffs_trial_button {
    padding-right: $padding-md * 2;
    flex-grow: 1;

    a {
      margin-bottom: $margin-md * 2;
    }
  }

  .tariffs_trial_button_note {
    line-height: 1.6;
    font: {
      size: $font-md2;
    }

    span {
      color: $danger;
    }
  }
}

.link_row {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: $content-width) {
  .container {
    padding: 0 20px;
  }

  .modal_container {
    padding: 0 20px;
  }
}