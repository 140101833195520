@import "styles/variables";

.root {
  z-index: 99999;
  position: relative;
  width: 100%;

  .menu {
    position: absolute;
    top: -1000px;
    width: 100%;
    //height: 100vh;
    background-color: #fff;
    transition: all 0.5s ease;
    z-index: 110;
    font-family: $montserrat;
  }

  &:global(.menu_opened) {
    .menu {
      display: block;
      top: $site-top-panel-height;
    }
  }

  nav>a {
    padding: 0 20px;
    display: block;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.25px;
    color: $landing-grey;
    text-transform: uppercase;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mobile_main_link {
    padding: 0 20px;
    border-bottom: 2px solid $landing-back-grey;
    margin: 26px 0;

    >a {
      display: flex;
      justify-content: space-between;
      margin-bottom: 26px;
      color: $landing-grey;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.25px;
      text-transform: uppercase;

      span {

      }
    }
  }
}

.top_panel_container {
  position: relative;
  color: $landing-grey;
  display: flex;
  align-items: center;
  background-color: #fff;
  height: $site-top-panel-height;
  z-index: 999;
  width: 100%;

  .right_block {
    margin-left: auto;
    display: flex;
  }
}

.logo {
  flex-grow: 1;
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 20px;

  @media (max-width: rem-calc(450)) {
    margin-right: 5px;
    line-height: 0;
  }

  img {
    height: 32px;

    @media (max-width: rem-calc(450)) {
      height: 23px;
    }
  }
}

.menu_button {
  padding: $padding-sm 0 $padding-sm $padding-sm;

  @media (max-width: rem-calc(450)) {
    line-height: 0;
  }

  svg {
    font-size: 40px;

    @media (max-width: rem-calc(450)) {
      font-size: 28px;
    }
  }
}

.action {
  margin-right: 20px;
  display: flex;

  @media (max-width: rem-calc(450)) {
    a:first-child{
      padding: 8px 7px;
      font-size: 10px;
    }
  }
}

.auth{
  background: #F6F8FA;
  cursor: pointer;
  padding: 0 6px;
  height: 33px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  border-radius: 6px;
  color: #274568;

  svg{
    color: #274568;
  }
}

.lang {
  margin-left: auto;
  padding-right: $padding-sm;
}

.profile {
  padding: 0 10px;
  svg {
    font-size: 40px;
  }
}