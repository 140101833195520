@import "styles/variables";

$social-img-size: rem-calc(32);

.root {
  position: relative;
  font-family: $montserrat;
  padding: 120px 0;
  background: $landing-dark-grey;
}

.container {
  position: relative;
  max-width: $max-page-width;
  margin: auto;
  //padding: 120px;
  z-index: 2;
}

.logo {
  height: 48px;
  margin-bottom: 100px;

  img {
    height: 48px;
  }
}

.menu {
  display: flex;
}

.block {
  width: 33.33%;
  margin-bottom: 80px;

  /*:global(nav > a:not(:last-child)::after) {
    position: absolute;
    right: -20px;
    content: '';
    display: block;
    margin-bottom: $margin-md;
  }*/

  :global(nav > a) {
    display: flex;
    align-items: center;
    position: relative;
    font-family: 'Noto Sans', 'Montserrat', sans-serif;
    margin-bottom: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    font-weight: 700;
    color: $landing-grey;
    text-transform: uppercase;
    transition: 0.1s color linear;
    color: $white;

    :global(span) {
      margin-right: 12px;
    }

    :global(svg) {
      font-size: 20px;
      line-height: 20px;
      opacity: 0;
      transition: 0.25s opacity linear;
      color: $primary;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $primary;
      :global(svg) {
        opacity: 1;
      }
    }
  }
}

.bottom {
  display: flex;
  letter-spacing: 0.5px;
  font-size: 16px;
  color: $brand-dark;
}

.contacts_container {
  flex-grow: 1;
  margin-bottom: 20px;
}

.address {
  padding-top: 10px;
  //margin-bottom: 20px;
  margin-right: 40px;
  //margin-right: 20px;
  letter-spacing: 0.5px;
  color: $white;

  &:hover {
    color: $primary;
  }

  svg {
    position: relative;
    top: 5px;
    margin-right: 10px;
  }
}

.map_link {

  :global(a) {
    display: flex;
    align-items: center;
    color: $white;
    text-transform: uppercase;
    font-family: 'Noto Sans', 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 0.25px;
    font-size: 14px;

    &:hover {
      color: $primary;
    }
  }

  :global(.MuiSvgIcon-root) {
    font-size: 20px;
    margin-right: 12px;
  }
}

.social_links {
  display: flex;
  align-items: center;
}

.social {
  position: relative;
  display: flex;
  flex-direction: column;

  .email {
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;

    a {
      color: $white;
      text-decoration: underline;
      transition: 0.1s color linear;

      &:hover {
        color: $primary;
      }
    }
  }

  //:global(:M)
  :global([class*="ui-circle-button_component"]) {
    //color: $white;
    &:not(:last-child) {
      margin-right: $margin-xs;
    }

    :global([class*="ui-circle-button_button"]) {
      background-color: transparent;
      color: $white;
    }
  }
}

.requisites {
  line-height: 2;
  margin-top: $margin-lg * 4;
  font-size: $font-sm;
  color: lighten($brand-middle-grey, 10%);
}

@media (max-width:rem-calc(1400)) {
  .root {
    padding: 60px;
  }
}

@media (max-width: $content-width) {
  .container {
    margin: 0;
  }
}

@media(max-width: $tablet-width) {
  .root {
    padding: 20px;
  }
  .container {
    padding: 20px;
  }

  .logo {
    margin-bottom: 80px;
  }

  .menu {
    flex-wrap: wrap;
  }

  .block {
    width: 50%;
  }
}

@media(max-width: $mobile-width) {
  .root{
    padding: 40px 0;
  }

  .block {
    width: 100%;
  }

  .bottom {
    flex-wrap: wrap;
  }

  .map_link {
    margin-bottom: 30px;
  }

  .social {
    left: -10px;
  }
}