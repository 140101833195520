@import "mixins/all";

$white:       				#ffffff;
$medium-gray: 				darken($white, 30%);
$black:       				#000;
$gray:        				#ddd;
$default-text-color: 	rgba(0, 0, 0, 0.85);
$light-gray-text-color: #9D9FA3;
$brand:            		#ff6100;
$brand-dark:          #5f6674;
$brand-darkest:       #484d58;
$brand-info:          #29d;
$brand-text-color: 		$white;
$brand-pale-grey:     #D9D9D9;
$brand-middle-grey:   lighten($brand-dark, 25%);
$label-color:         lighten($brand-dark, 20%);
$brand-yellow:        #ffd726;
$success:          		#329f74;
$warning:          		#fcf8e3;
$danger:           		#ff0034;
$danger-dark:         #db2828;
$info:             		rgb(0, 188, 212);
$info-dark:           darken($info, 15%);
$zengine-dark:     		#484d58;
$menu-badge:       		#f8e81c;
$logo:             		$white;
$muted-text-color: 		darken($white, 40%);
$close-btn-color:  		#95979c;
$teal:             		#00B5AD;
$link-color:          #4183C4;
$link-hover-color:    darken(#4183C4, 10%);
$disabled-btn-bg:     #f2f2f2;
$orange:              #ff6100;
$orange-light:        #FFA901;
$orange-text:         #D98F00;
$orange-10:           #fff9e6;
$orange-20:           #fff2cc;
$red:                 #ff0000;
$dark-red:            #C10000;
$red-10:              #ffe6e6;
$red-15:              #ffd9d9;
$red-20:              #ffcccc;
$red-30:              #ffb3b3;

$top-panel-height: rem-calc(48);
$site-top-panel-height: rem-calc(80);
$mechanic-menu-height: rem-calc(58);
$manager-menu-categories-width: rem-calc(68);
$side-menu-width: rem-calc(300);
$site-menu-width: rem-calc(320);
$side-menu-minimized-width: $top-panel-height;
$finder-height: rem-calc(77);

$site-header-height: rem-calc(110);
$site-header-height-mobile: rem-calc(67);
$max-page-width: rem-calc(1360);

$icons-font-path: '~semantic-ui-sass/icons';
$app-bg: #e4e7f0;
$app-medium-bg: lighten(#e4e7f0, 4%);
$app-light-bg: #F8F8FB;

$font-light: "OpenSans-Light", 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$font-regular: "OpenSans-Regular", 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$font-semibold: "OpenSans-SemiBold", 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$font-bold: "OpenSans-Bold", 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$font-extrabold: "OpenSans-ExtraBold", 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$openSans: "OpenSans", 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$montserrat: "Montserrat", 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

$import-google-fonts: false;

$font-xs: rem-calc(9);
$font-xs1: rem-calc(10);
$font-xs2: rem-calc(11);
$font-sm: rem-calc(12);
$font-sm1: rem-calc(13);
$font-sm2: rem-calc(14);
$font-md: rem-calc(15);
$font-md1: rem-calc(16);
$font-md2: rem-calc(17);
$font-lg: rem-calc(19);
$font-lg1: rem-calc(20);
$font-lg2: rem-calc(21);
$font-xl: rem-calc(23);
$font-xxl: rem-calc(27);

$def-br: rem-calc(10);
$small-br: rem-calc(5);

$default-border-color: $app-bg;
$default-border: rem-calc(1) solid $default-border-color;
$dotted-border: rem-calc(1) dotted $default-border-color;
$dashed-border: rem-calc(1) dashed $default-border-color;
$light-border: rem-calc(1) solid lighten($default-border-color, 5%);

$default-box-shadow: 0 0 rem-calc(10) rgba($brand-darkest, 0.2);
$selected-box-shadow: 0 0 rem-calc(10) rgba($info, 0.4);
$panel-box-shadow: 2px 2px 6px 3px $app-bg;

$margin-xs: rem-calc(5);
$margin-sm: rem-calc(10);
$margin-md: rem-calc(15);
$margin-lg: rem-calc(20);
$margin-xl: rem-calc(25);
$margin-xxl: rem-calc(30);

$padding-xs: rem-calc(5);
$padding-sm: rem-calc(10);
$padding-md: rem-calc(15);
$padding-lg: rem-calc(20);
$padding-xl: rem-calc(25);
$padding-xxl: rem-calc(30);

$mobile-width: rem-calc(650);
$tablet-width: rem-calc(1100);
$default-view-width: rem-calc(1250);

//Landing
$font-color:          rgba(0, 16, 35, 0.9);
$landing-overlay:     rgba(0, 16, 35, 0.9);
$landing-dark-grey:   #16293F;
$landing-grey:        #274568;
$landing-grey-70:     rgba(39, 69, 104, 0.7);
$landing-grey-50:     rgba(39, 69, 104, 0.5);
$landing-grey-30:     rgba(39, 69, 104, 0.3);
$landing-grey-10:     rgba(39, 69, 104, 0.1);
$landing-back-grey:   #F6F8FA;
$primary:             #ff204e;
$primary-dark:        #BE0027;
$primary-light:       rgba(255, 0, 52, 0.7);
$primary-50:          rgba(255, 0, 52, 0.5);
$primary-30:          rgba(255, 0, 52, 0.3);
$primary-10:          rgba(255, 0, 52, 0.1);
$secondary:           #00b3a8;
$secondary-hover:     #009c95;
$secondary-dark:      #008681;
$secondary-light:     rgba(0, 181, 173, 0.7);
$section-gray-bg:     #f5f5f8;
$brand-gray:          #A2A6B1;
$border-gray:         #b7bec1;

$content-width: 1400px;