@import "styles/variables";

.root {
  font-family: 'Noto Sans', 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  color: $landing-grey;
  transition: color 0.2s linear;

  svg {
    margin-left: 15px;
    font-size: 20px;
  }

  &:hover {
    color: $primary;
  }
}